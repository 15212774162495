import React from "react"

import Layout from "../components/flayout"
import ContentPage from "../components/contentPage"
import styles from "../components/contentPage/styles.module.css"
import ProceedButton from "../components/proceedButton";
import FeatureAllInvestmentsGraphic from "../gatsby-images/feature-all-investments-graphic";

const Page = () => (
  <Layout darkHeader={true} headerFixed={false}>
    <ContentPage
      title={'MProfit 10 Beta Launch'}
    >
      <h3>New MProfit with Cloud & Mobile Application – Beta Version available</h3>

      <p>We are happy to announce that a new version of MProfit is now available in Beta. This new update comes packaged with the Phase 1 launch of our much awaited MProfit Cloud platform for Free & Paid Users.</p>

      <div className={["row",styles.imageRowContainer].join(" ")}>
        <div style={{position:'relative'}} className={"feature_img_box col-md-8 col-sm-12"}>
          <FeatureAllInvestmentsGraphic/>
        </div>
        <div className="col-md-4 col-sm-12">
          <h4>What this means for you:</h4>
          <p>With MProfit 10, you will now be able to manage your investments in MProfit, sync your data to Cloud, and view your MProfit portfolios on web browsers, mobile or tablet devices. By doing so, you agree to become an MProfit 10 Beta user.</p>
        </div>
      </div>

      <h4>If you are willing to try the new MProfit Beta version, you can easily update to MProfit 10 with the following steps:</h4>

      <ul>
        <li>As this is a major update, we first recommend that you take a back-up of all your MProfit data on an external hard-drive or pen-drive.</li>
        <li>Irrespective of whether you are an MProfit Investor / Pro / Advisor user, download and run the setup from the link below and the appropriate version will be installed.</li>
        <li>Once you have MProfit 10 successfully installed and running on your machine, you have the option to sync your data to Cloud and create one free login for viewing data on web browsers, mobile and tablet (Android / iOS). In the MProfit 10 desktop application, simply click the <strong>Sync Data</strong> button to get started with MProfit Cloud.</li>
        <li><strong>Multi-user MProfit Clients:</strong> Please email us at support@mprofit.in before completing any of the above steps, and our support team will guide you through the installation process.</li>
      </ul>

      <div className="d-flex justify-content-center">
        <div style={{marginTop: 30}}>
          <ProceedButton buttonText={'Update to MProfit 10 Now'} borderRadius={4} onClick={downloadBeta}/>
        </div>
      </div>

    </ContentPage>
  </Layout>
)

const downloadBeta = () => {
  window.location.href = 'https://files.mprofit.in/files/MProfitUpdate10.exe';
}

export default Page
